const resource = {
    en: {
        planungsmatrix: 'Planungsmatrix',
        my_matrices: 'My Matrices',
        shared_matrices: 'Shared Matrices',
        learning_classes: 'Learning Classes',
        create_learning_class: 'Create Learning Classes',
        show_more: 'show more',
        create_new: 'Create new',
        enter_search_term: 'Enter Search Term',
        search: 'Search',
        no_matrices_available: 'No Matrices available',
    },
    de: {
        planungsmatrix: 'Planungsmatrix',
        my_matrices: 'Meine Matrizen',
        shared_matrices: 'Für Mich Freigegebene Matrizen',
        learning_classes: 'Lernklassen',
        create_learning_class: 'Lernklasse Erstellen',
        show_more: 'mehr anzeigen',
        create_new: 'Neue Erstellen',
        enter_search_term: 'Suchbegriff eingeben',
        search: 'Suchen',
        no_matrices_available: 'Keine Matrizen verfügbar',
    }
}

export default resource;