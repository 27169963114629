const resource = {
    en: {
        share: 'share',
        delete: 'delete',
        edit: 'edit',
        review: 'review',
        last_edited: 'Last edited on',
        status: 'Status',
        status_options: {
            in_edit: 'IN EDIT',
            for_review: 'FOR REVIEW',
            public: 'PUBLIC',
            finish: 'FINISH',
        }
    },
    de: {
        share: 'teilen',
        delete: 'löschen',
        edit: 'bearbeiten',
        review: 'review',
        last_edited: 'Zuletzt verändert am',
        status: 'Status',
        status_options: {
            in_edit: 'IN BEARBEITUNG',
            for_review: 'FÜR REVIEW',
            public: 'ÖFFENTLICH',
            finish: 'VOLLENDET',
        }
    }
}

export default resource;