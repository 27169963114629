const USER_ROLES = {
    ADMIN: 1,
    SUPER_USER: 2,
    LEARNING_CLASS_EDITOR: 3,
    LEARNING_MODULE_EDITOR: 4,
    BASIC_USER: 5,
};

async function getAbility(ability_name, user, item = undefined) {
    // check if user is available
    if (!user) return false;

    // extract user roles
    const user_roles_ids = await getArray(user.user_role_ids);

    // ALL ABILITIES

    // admin | NONE
    if (ability_name === 'admin') {
        return  user_roles_ids.includes(USER_ROLES.ADMIN);

    // super user | NONE
    } else if (ability_name === 'super-user') {
        return  user_roles_ids.includes(USER_ROLES.SUPER_USER);

    // learning module editor | NONE
    } else if (ability_name === 'learning-module-editor') {
        return user_roles_ids.includes(USER_ROLES.LEARNING_MODULE_EDITOR);

    // create-matrix | NONE
    } else if (ability_name === 'create-matrix') {
        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                user_roles_ids.includes(USER_ROLES.SUPER_USER) ||
                user_roles_ids.includes(USER_ROLES.BASIC_USER);

    // read-matrix | MATRIX
    // create-matrix-comment | MATRIX
    // create-matrix-comment | SEQUENCE
    } else if ( ability_name === 'read-matrix' || 
                ability_name === 'create-matrix-comment') {
        if (!item) return false;
        if (!item['editor_ids'] || 
            !item['reviewer_ids'] ||
            !item['user_id'] && !item['matrix']) return false;

        const editor_ids = await getArray(item.editor_ids);
        const reviewer_ids = await getArray(item.reviewer_ids);

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                user_roles_ids.includes(USER_ROLES.SUPER_USER) ||
                item.user_id == user.id ||
                editor_ids.includes(user.id) ||
                reviewer_ids.includes(user.id);

    // update-matrix | MATRIX
    } else if (ability_name === 'update-matrix') {
        if (!item) return false;
        if (!item['editor_ids'] || 
            !item['reviewer_ids']) return false;

        const editor_ids = await getArray(item.editor_ids);

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id ||
                editor_ids.includes(user.id);

    // delete-matrix | MATRIX
    // share-matrix | MATRIX
    } else if ( ability_name === 'delete-matrix' || 
                ability_name === 'share-matrix') {

        if (!item) return false;
        if (!item['user_id']) return false;

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id;

    // update-matrix-comment | MATRIX COMMENT
    // delete-matrix-comment | MATRIX COMMENT
    } else if ( ability_name === 'update-matrix-comment' ||
                ability_name === 'delete-matrix-comment') {
        if (!item) return false;
        if (!item['user_id']) return false;

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id;

    // resolve-matrix-comment | MATRIX COMMENT
    } else if (ability_name === 'resolve-matrix-comment') {
        if (!item) return false;
        if (!item['matrix'] && !(item['sequence'] && item['sequence']['matrix'])) return false;

        let editor_ids = [];
        let reviewer_ids = [];

        if (item['matrix']) {
            editor_ids = await getArray(item.matrix.editor_ids);
            reviewer_ids = await getArray(item.matrix.reviewer_ids);
        } else {
            editor_ids = await getArray(item.sequence.matrix.editor_ids);
            reviewer_ids = await getArray(item.sequence.matrix.reviewer_ids);
        }
        
        if (!item['matrix']) {
            return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.sequence.matrix.user_id == user.id ||
                editor_ids.includes(user.id) ||
                reviewer_ids.includes(user.id);
        } else {
            return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.matrix.user_id == user.id ||
                editor_ids.includes(user.id) ||
                reviewer_ids.includes(user.id);
        }
    // create-learning-class | NONE
    } else if (ability_name === 'create-learning-class') {
        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                user_roles_ids.includes(USER_ROLES.SUPER_USER) ||
                user_roles_ids.includes(USER_ROLES.LEARNING_CLASS_EDITOR);
                
    // update-learning-class | LEARNING CLASS
    } else if (ability_name === 'update-learning-class') {
        if (!item) return false;
        if (!item['teacher_ids']) return false;

        const teacher_ids = await getArray(item.teacher_ids);

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id ||
                teacher_ids.includes(user.id);

    // delete-learning-class | LEARNING CLASS
    } else if (ability_name === 'delete-learning-class') {
        if (!item) return false;

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id;

    // create-learning-class-member-message | LEARNING CLASS
    } else if (ability_name === 'create-learning-class-member-message') {
        if (!item) return false;
        if (!item['teacher_ids'] || !item['member_ids']) return false;

        const teacher_ids = await getArray(item.teacher_ids);
        const member_ids = await getArray(item.member_ids);

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id ||
                teacher_ids.includes(user.id) ||
                member_ids.includes(user.id);

    // create-learning-class-teacher-message | LEARNING CLASS
    } else if (ability_name === 'create-learning-class-teacher-message') {
        if (!item) return false;
        if (!item['teacher_ids']) return false;

        const teacher_ids = await getArray(item.teacher_ids);

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id ||
                teacher_ids.includes(user.id);

    // update-learning-class-message | LEARNING CLASS MESSAGE
    // delete-learning-class-message | LEARNING CLASS MESSAGE
    } else if ( ability_name === 'update-learning-class-message' ||
                ability_name === 'delete-learning-class-message') {
        if (!item) return false;

        return  user_roles_ids.includes(USER_ROLES.ADMIN) ||
                item.user_id == user.id;

    }




    // FILES

    // LEARNING MODULE PAGE

    // LEARNING CLASS

    // LEARNING CLASS MESSAGE

    // USER ROLE

    // USER
    

    return false;
}

function getArray(items) {
    return new Promise((resolve) => {
        const temp_array = [];

        for (let i in items) {
            temp_array.push(items[i]);
        }

        resolve(temp_array);
    })
}

export { USER_ROLES, getAbility };