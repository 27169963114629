<template>
    <BaseModal wide ref="base_modal" @close="cancel">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <label 
            class="modal-heading-2" 
            v-if="selected_users.length > 0" 
            v-html="resource[lang].selected" 
        />
        <UserInfoCard 
            class="user"
            v-for="(user, index) in selected_users" 
            :key="index"
            :user="user"
            @click="deselectUser(index)"
        />

        <div class="grid">
            <InputText :label="resource[lang].enter_search_term" v-model="search_term" />
            <Button color="primary" :label="resource[lang].search" @click="search" />
        </div>

        <label class="modal-heading-2" v-html="resource[lang].users" />
        <UserInfoCard 
            class="user"
            v-for="(user, index) in users" 
            :key="index"
            :user="user"
            @click="selectUser(index)"
        />
        <div v-if="applied_search_term != '' && users.length === 0" class="not-found">
            <p class="modal-text">No users found.</p>
        </div>
        <Button 
            v-if="!all_users"
            class="show-more" 
            color="primary" 
            :label="resource[lang].show_more" 
            @click="getUsers" 
        />

        <div class="actions center">
            <Button color="accent" :label="resource[lang].share_for_review" @click="shareForReview" />
            <Button color="accent" :label="resource[lang].share_for_edit" @click="shareForEdit" />
            <Button color="white" :label="resource[lang].cancel" @click="cancel" />
        </div>
    </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/share-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'
import UserInfoCard from '@/components/UserInfoCard'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    props: {
        matrix: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseModal,
        Button,
        InputText,
        UserInfoCard
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            selected_users: [],

            users: [],
            user_page: 1,
            all_users: false,

            search_term: '',
            applied_search_term: '',

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.getUsers();
    },
    methods: {
        // Requests
        getUsersRequest() {
            if (this.search_term) {
                return promiseGetRequest(`users?page=${this.user_page}&search=${this.applied_search_term}`)
            } else {
                return promiseGetRequest(`users?page=${this.user_page}`)
            }
        },

        // Actions
        cancel() {
            this.$refs.base_modal.closeModal();
            this.$emit('cancel');
        },
        shareForEdit() {
            const user_ids = this.selected_users.map(user => user.id);
            this.$emit('share', user_ids, true);
            this.$refs.base_modal.closeModal();
        },
        shareForReview() {
            const user_ids = this.selected_users.map(user => user.id);
            this.$emit('share', user_ids, false);
            this.$refs.base_modal.closeModal();
        },

        // Functions
        async getUsers() {
            this.$store.dispatch('loading', true)
            const response = await this.getUsersRequest();
            this.$store.dispatch('loading', false)

            this.users = [...this.users, ...response.data]
            this.user_page += 1
            if (response.data.length < 10) {
                this.all_users = true;
            }

            this.users = await this.filterUsers(this.users);
        },
        selectUser(user_index) {
            this.selected_users.push(this.users[user_index])
            this.users.splice(user_index, 1)
        },
        deselectUser(user_index) {
            this.users.push(this.selected_users[user_index])
            this.selected_users.splice(user_index, 1)
        },
        search() {
            this.applied_search_term = this.search_term
            this.users = []
            this.user_page = 1
            this.all_users = false

            this.getUsers();
        },
        filterUsers(users) {
            return new Promise((resolve) => {
                let temp_users = users;
                temp_users = temp_users.filter(temp_user => temp_user.id != this.user.id);

                for (let user of this.selected_users) {
                    temp_users = temp_users.filter(temp_user => temp_user.id != user.id);
                }

                if (this.matrix) {
                    for (let user of this.matrix.editors) {
                        temp_users = temp_users.filter(temp_user => temp_user.id != user.id);
                    }

                    for (let user of this.matrix.reviewers) {
                        temp_users = temp_users.filter(temp_user => temp_user.id != user.id);
                    }
                }

                resolve(temp_users);
            })
            
        }
    },
    emits: ['share', 'cancel']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 2rem;
}

.actions > * + * {
    margin-inline-start: 1rem;
}

.grid {
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
}

.show-more {
    margin-block-start: 1rem;
    margin-block-end: 2rem;
}

.user + .user {
    margin-block-start: 0.5rem;
}

@media (max-width: 40rem) {

    button,
    .actions > * {
        width: 100% !important;
    }

    .actions > * + * {
        margin: 0;
        margin-block-start: 1rem;
    }

    .grid {
        display: block;

        button {
            margin-block-start: 1rem;
        }
    }
}

</style>