<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].my_matrices" />
        
        <div class="search grid">
            <InputText :label="resource[lang].enter_search_term" v-model="search_term" />
            <Button color="primary" :label="resource[lang].search" @click="search" />
        </div>

        <DashboardListItem 
            v-for="matrix in matrices" 
            :key="matrix.id" 
            :matrix="matrix" 
            @delete="showDeleteModal(matrix.id)"
            @share="showShareModal(matrix.id)"
        />

        <div class="not-found" v-if="matrices.length == 0">
            <p class="text" v-html="resource[lang].no_matrices_available" />
        </div>

        <div class="actions">
            <Button v-if="!all_matrices" color="primary" :label="resource[lang].show_more" @click="getMyMatrices" />
            <Button v-if="create" color="accent" :label="resource[lang].create_new" @click="$router.push('/matrix')" />
        </div>

        <DeleteModal 
            v-if="showDeleteModalFlag" 
            @confirm="confirmDelete"
            @cancel="cancelDelete"
        />

        <ShareModal
            v-if="showShareModalFlag"
            @share="handleShare"
            @cancel="cancelShare"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/dashboard-resource'

import Button from '@/components/basic/Button'
import DashboardListItem from '@/components/DashboardListItem'
import DeleteModal from '@/components/modals/DeleteModal'
import InputText from '@/components/basic/InputText'
import ShareModal from '@/components/modals/ShareModal'

import { promiseDeleteRequest, promiseGetRequest, promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    components: {
        Button,
        DashboardListItem,
        DeleteModal,
        InputText,
        ShareModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            matrices: [],
            matrix_page: 1,
            all_matrices: true,

            showShareModalFlag: false,
            share_id: null,

            showDeleteModalFlag: false,
            delete_id: null,

            // user abilities
            create: false,

            // search
            search_term: '',
            applied_search_term: '',

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.getMyMatrices();
            this.getAbilities();
        },
        async getAbilities() {
            this.create = await getAbility('create-matrix', this.user);
        },

        // Requests
        getMyMatricesRequest() {
            let route = `my-matrices?page=${this.matrix_page}`;

            if (this.applied_search_term) {
                route = `${route}&search=${this.applied_search_term}`;
            }

            return promiseGetRequest(route);
        },
        deleteMatrixRequest() {
            return promiseDeleteRequest(`matrix/${this.delete_id}`);
        },
        shareMatrixRequest(request_body) {
            return promisePutRequest(`share-matrix/${this.share_id}`, request_body);
        },

        // Actions
        showDeleteModal(id) {
            this.delete_id = id;
            this.showDeleteModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        showShareModal(id) {
            this.share_id = id;
            this.showShareModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelDelete() {
            console.log('canceling delete')
            this.delete_id = null;
            this.showDeleteModalFlag = false;
        },
        cancelShare() {
            this.share_id = null;
            this.showShareModalFlag = false;
        },
        async confirmDelete() {
            this.$store.dispatch('loading', true);
            await this.deleteMatrixRequest();
            this.$store.dispatch('loading', false);

            this.matrices = this.matrices.filter(matrix => matrix.id !== this.delete_id);
            this.delete_id = null;
            this.showDeleteModalFlag = false;   
        },
        async handleShare(user_ids, can_edit) {
            const request_body = {
                user_ids: user_ids,
                can_edit: can_edit
            }
            this.$store.dispatch('loading', true);
            await this.shareMatrixRequest(request_body);
            this.$store.dispatch('loading', false);

            this.share_id = null;
            this.showShareModalFlag = false;   
        },
        search() {
            this.applied_search_term = this.search_term;

            this.matrices = [];
            this.matrix_page = 1;

            this.getMyMatrices();
        },

        // Functions
        async getMyMatrices() {
            this.$store.dispatch('loading', true)
            const response = await this.getMyMatricesRequest();
            this.$store.dispatch('loading', false)

            this.matrices = [...this.matrices, ...response.data]
            this.matrix_page += 1

            if (response.data.length < 10) {
                this.all_matrices = true;
            } else {
                this.all_matrices = false;
            }
        }
    },
    watch: {
        user: async function() {
            this.getAbilities();
        }
    },
    beforeRouteEnter(to, from, next) {
        const token = localStorage.getItem('jwt_token');

        if (token) {
            next()
        } else {
            next({name: 'Login'})
        }
    },
}
</script>

<style lang="scss" scoped>

.actions > * + * {
    margin-inline-start: 1rem;
}

.dashboard-main > * + * {
    margin-top: 1rem;
}

@media (max-width: 40rem) {
    .actions > * {
        width: 100%;
    }

    .actions > * + * {
        margin: 0;
        margin-block-start: 1rem;
    }
}

</style>