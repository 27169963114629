const resource = {
    en: {
        heading: 'Share Matrix',
        selected: 'Selected Users',
        users: 'Users',
        show_more: 'show more',
        share_for_edit: 'Share for edit',
        share_for_review: 'Share for review',
        enter_search_term: 'Enter search term...',
        search: 'Search',
        cancel: 'Cancel'
    },
    de: {
        heading: 'Matrix Teilen',
        selected: 'Ausgewählte Anwender*innen',
        users: 'Anwender*innn',
        show_more: 'mehr anzeigen',
        share_for_edit: 'Zur Bearbeitung freigeben',
        share_for_review: 'Zum Review freigeben',
        enter_search_term: 'Suchwort eingeben...',
        search: 'Suchen',
        cancel: 'Abbrechen'
    }
}

export default resource;