<template>
    <div class="item-wrapper flex">
        <div class="info">
            <router-link class="item-name" :to="`/matrix/${matrix.id}`" v-html="matrix.name" />
            <p class="item-description"
                v-html="`${resource[lang].last_edited} ${date} | ${resource[lang].status}: ${resource[lang].status_options[matrix.status]} | ${matrix.owner.full_name}`" 
            />
            
            <div v-if="matrix.unresolved_comments && matrix.status != 'finish' && matrix.status != 'public'" class="indicator" v-html="'|'" />
        </div>
        <div class="actions">
            <Button v-if="owner" color="secondary" :label="resource[lang].share" @click="$emit('share')" />
            <Button v-if="owner" color="primary" :label="resource[lang].delete" @click="$emit('delete')" />
            <Button v-if="editor" color="secondary" :label="resource[lang].edit" @click="$router.push(`/matrix/${matrix.id}`)" />
            <Button v-if="reviewer" color="secondary" :label="resource[lang].review" @click="$router.push(`/matrix/${matrix.id}`)" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import resource from '@/assets/resources/dashboard-list-item-resource'

import Button from '@/components/basic/Button'

export default {
    inject: ['$dateOptions'],
    props: {
        matrix: {
            type: Object,
            required: true
        }
    },
    components: {
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        date() {
            return new Date(this.matrix.updated_at).toLocaleDateString('en-GB', this.$dateOptions);
        },
        owner() {
            if (this.user && this.matrix.user_id === this.user.id) {
                return true
            } else {
                return false
            }
        },
        editor() {
            if (this.matrix.pivot) {
                if (this.matrix.pivot.can_edit) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        reviewer() {
            if (this.matrix.pivot) {
                if (this.matrix.pivot.can_edit) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    },
    emits: ['share', 'delete']
}
</script>

<style lang="scss" scoped>

.actions {
    text-align: right;
    min-width: 24rem;
}

.actions > * + * {
    margin-inline-start: 1rem;
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;

    background-color: var(--light_blue);
    color: var(--light_blue);
    border: none;

    width: 8px;
    height: calc(100% - 10px);

    margin: 5px;
}

.item-description {
    color: var(--dark_grey);

    font-family: var(--standard-font);
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2;

    @media (max-width: 40rem) {
        font-size: 0.7rem;
    }
}

.item-name {
    display: block;
    color: var(--blak);

    font-family: var(--standard-font);
    font-size: 2.1rem;
    font-weight: 400;

    line-height: 1.2;
    padding-block-end: 0.5rem;

    &:hover {
        color: var(--primary);
    }

    @media (max-width: 40rem) {
        font-size: 1.5rem;
    }
}

.item-wrapper {
    position: relative;

    background-color: var(--whit);

    padding: 1rem;
    padding-left: 1.5rem;

    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
}

@media (max-width: 64rem) {

    .actions {
        text-align: right;
        min-width: 10rem;
    }

    .actions > * {
        display: block;
        width: 100%;
    }

    .actions > * + * {
        margin: 0;
        margin-block-start: 1rem;
    }
}

@media (max-width: 40rem) {

    .actions {
        margin-block-start: 2rem;
    }

    .flex {
        display: block;
    }
}

</style>